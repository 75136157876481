"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createReq = createReq;
exports.updateReq = updateReq;
exports.deleteReq = deleteReq;
exports.deleteReqs = deleteReqs;
exports.detailReq = detailReq;
exports.listAll = listAll;
exports.retrieveReq = retrieveReq;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 * @Description: 上游客户任务
 * @Author: june
 */
// 新增
function createReq(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerTasks",
    method: "post",
    data: data
  });
} // 修改


function updateReq(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerTasks",
    method: "put",
    data: data
  });
} // 单个删除


function deleteReq(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerTasks/".concat(params),
    method: "delete"
  });
} // 批量删除


function deleteReqs(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerTasks",
    method: "delete",
    data: data
  });
} // 根据id查找


function detailReq(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerTasks/taxation/".concat(data),
    method: "get"
  });
} // 查找所有


function listAll(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerTasks/listAll",
    method: "get",
    params: params
  });
} // 分页查询


function retrieveReq(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerTasks/taxationPage",
    method: "get",
    params: params
  });
}