"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

//
//
//
var _default = {
  components: {},
  props: {},
  data: function data() {
    return {};
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)(["settings"])),
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};
exports.default = _default;