"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api");

var _tool = require("@/utils/tool");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      title: "自由职业者",
      info: {}
    };
  },
  created: function created() {
    // 获取参数
    var id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.title = this.$route.query.title;
    this.getData(id);
  },
  methods: {
    goBack: function goBack() {
      this.$router.go(-1);
    },
    getData: function getData(id) {
      var _this = this;

      (0, _api.detailReq)(id).then(function (res) {
        var data = (0, _tool.convertPic)(res.data, "idcardFront", "idcardBack", "facePic", "caPic", "sstAuth");
        _this.info = data;
      });
    }
  }
};
exports.default = _default;