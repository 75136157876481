"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.function.name");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/objectSpread2"));

var _logo2 = _interopRequireDefault(require("@/assets/images/logo.png"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    collapsed: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {};
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)(["settings"])), (0, _vuex.mapGetters)(["permissions_site"])), {}, {
    sysName: function sysName() {
      var currentSite = this.permissions_site.filter(function (item) {
        return item.currSite === true;
      });

      if (currentSite.length > 0) {
        return currentSite[0]["name"];
      }

      return this.settings.system_title;
    },
    logo: function logo() {
      var currentSite = this.permissions_site.filter(function (item) {
        return item.currSite === true;
      });

      if (currentSite.length > 0) {
        if (!!currentSite[0]["pic"]) {
          return currentSite[0]["pic"];
        }
      }

      return _logo2.default;
    }
  }),
  watch: {
    sysName: function sysName(val) {
      this.$store.commit("settings/updateSetting", {
        system_title: val
      });
    }
  }
};
exports.default = _default;