"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  id: 1,
  parentId: 0,
  icon: "dashboard",
  name: "首页总览",
  url: "/dashboard"
}, {
  id: 200,
  parentId: 0,
  icon: "chart",
  name: "基础信息",
  url: "/basic"
}, {
  id: 210,
  parentId: 200,
  name: "公司信息",
  url: "/basic/company"
}, {
  id: 220,
  parentId: 200,
  name: "公司资料库",
  url: "/document"
}, {
  id: 230,
  parentId: 200,
  name: "上游客户",
  url: "/basic/customer"
}, {
  id: 240,
  parentId: 200,
  name: "自由职业者",
  url: "/basic/freelance"
}, {
  id: 300,
  parentId: 0,
  icon: "employee",
  name: "合同管理",
  url: "/contract"
}, {
  id: 310,
  parentId: 300,
  name: "上游合同",
  url: "/contract/customerContract"
}, {
  id: 320,
  parentId: 300,
  name: "自由职业者合同",
  url: "/contract/freelanceContract"
}, {
  id: 330,
  parentId: 300,
  name: "第三方合同",
  url: "/contract/other"
}, {
  id: 400,
  parentId: 0,
  icon: "zhihuihouqin",
  name: "任务管理",
  url: "/task"
}, {
  id: 410,
  parentId: 400,
  name: "上游任务管理",
  url: "/task/customerTask"
}, // {
//   id: 420,
//   parentId: 400,
//   name: "平台接包管理",
//   url: "/task/accept",
// },
{
  id: 430,
  parentId: 400,
  name: "平台分包管理",
  url: "/task/freelanceTask"
}, {
  id: 500,
  parentId: 0,
  icon: "pay",
  name: "费用管理",
  url: "/cost"
}, // {
//   id: 510,
//   parentId: 500,
//   name: "上游客户充值记录",
//   url: "/cost/customerRecharge",
// },
// {
//   id: 511,
//   parentId: 500,
//   name: "上游客户余额",
//   url: "/cost/customerBalance",
// },
// {
//   id: 512,
//   parentId: 500,
//   name: "上游客户订单",
//   url: "/cost/customerOrder",
// },
{
  id: 520,
  parentId: 500,
  name: "上游客户流水",
  url: "/cost/customerRecord"
}, {
  id: 530,
  parentId: 500,
  name: "自由职业者流水",
  url: "/cost/freelancePayRecord"
}, {
  id: 600,
  parentId: 0,
  icon: "asset",
  name: "发票管理",
  url: "/invoice"
}, {
  id: 610,
  parentId: 600,
  name: "自由职业者代开",
  url: "/invoice/freelanceInvoice"
}, {
  id: 620,
  parentId: 600,
  name: "上游开票记录",
  url: "/invoice/customerInvoice"
}];
exports.default = _default;