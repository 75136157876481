"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  copyright_title: "有活",
  // 版权标题
  system_title: "灵活用工",
  // 系统标题
  need_subject: false,
  // 使用主体切换功能
  subject_title: "物业公司",
  // 主体标题，物业系统为物业公司
  need_project: false,
  // 使用项目切换功能
  project_title: "小区",
  // 项目标题，物业系统为小区
  fixed_header: false,
  // 顶部固定
  need_logo: true,
  // 使用侧边栏 Logo
  need_tab: false,
  // 使用 Tab
  login_wx: false,
  use_site_name: false
};
exports.default = _default;