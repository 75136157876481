"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _sidebar = _interopRequireDefault(require("./components/sidebar"));

var _navbar = _interopRequireDefault(require("./components/navbar"));

var _appmain = _interopRequireDefault(require("./components/appmain"));

var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));

var _selectRequest = require("@/utils/selectRequest");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 通用上游客户下拉数据
var _default = {
  name: "Layout",
  components: {
    sidebar: _sidebar.default,
    navbar: _navbar.default,
    AppMain: _appmain.default
  },
  mixins: [_ResizeHandler.default],
  data: function data() {
    return {
      collapsed: false
    };
  },
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    },
    device: function device() {
      return this.$store.state.app.device;
    },
    fixed_header: function fixed_header() {
      return this.$store.state.settings.fixed_header;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  },
  created: function created() {
    // 上游客户名称
    (0, _selectRequest.customersList)().then(function (res) {
      var arr = [];
      res.data.forEach(function (item) {
        arr.push({
          value: item.gsmc,
          label: item.gsmc
        });
      });
      sessionStorage.setItem("customersList", JSON.stringify(arr));
    }); // 公司名称

    (0, _selectRequest.companiesList)().then(function (res) {
      var arr = [];
      res.data.forEach(function (item) {
        arr.push({
          value: item.gsmc,
          label: item.gsmc
        });
      });
      sessionStorage.setItem("companiesList", JSON.stringify(arr));
    });
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", {
        withoutAnimation: false
      });
    }
  }
};
exports.default = _default;