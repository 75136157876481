"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.find");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.splice");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.split");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _logo = _interopRequireDefault(require("./components/logo"));

var _footer = _interopRequireDefault(require("./components/footer"));

var _SubMenu = _interopRequireDefault(require("./components/SubMenu"));

var _item = _interopRequireDefault(require("./components/item"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
function buildKeys(str) {
  var paths = str.split("/");
  paths.splice(0, 1);
  var keys = [];
  var baseUrl = "";

  for (var i = 0; i < paths.length; i++) {
    baseUrl += "/" + paths[i];
    keys.push(baseUrl);
  }

  return keys;
}

var _default = {
  name: "Sidebar",
  components: {
    "xq-footer": _footer.default,
    logo: _logo.default,
    SubMenu: _SubMenu.default,
    Item: _item.default
  },
  props: {},
  data: function data() {
    return {
      hasLogo: true,
      selectedKeys: [],
      openKeys: []
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["sidebar", "permissions_menu", "permissions_menu_tree", "current_tab"])), {}, {
    isCollapsed: function isCollapsed() {
      return !this.sidebar.opened;
    }
  }),
  watch: {
    current_tab: function current_tab(val) {
      if (val === "/dashboard") {
        this.selectedKeys = [];
        this.openKeys = [];
      } else {
        this.selectedKeys = [val];
      }
    },
    $route: function $route(to) {
      if (to.path === "/dashboard") {
        this.selectedKeys = [];
        this.openKeys = [];
      } else {
        this.selectedKeys = [to.path];
      }
    }
  },
  created: function created() {
    this.initMenuKeys();
    this.openKeys = [this.familyTree(this.$router.options.routes, this.$router.currentRoute.path)];
    this.selectedKeys = [this.$router.currentRoute.path];
  },
  methods: {
    familyTree: function familyTree(arr1, url) {
      var path = "";
      arr1.forEach(function (element) {
        if (element.children) {
          element.children.forEach(function (esy) {
            if (esy.url === url) {
              path = element.url;
            }
          });
        }
      });
      return path;
    },
    initMenuKeys: function initMenuKeys() {
      if (this.$route.path !== this.current_tab) {
        if (this.$route.matched.length > 0) {
          var fullPath = this.$route.matched[this.$route.matched.length - 1]["path"];
          this.selectedKeys = buildKeys(fullPath);
          this.openKeys = buildKeys(fullPath);
        } else {
          this.openKeys = [];
        }
      }
    },
    onSelect: function onSelect(val) {
      var res = this.permissions_menu.find(function (menu) {
        return menu.url === val.key;
      });

      if (res) {
        this.$store.commit("navtab/addTab", res);
        this.$store.commit("navtab/setCurrentTab", val.key);
      }
    }
  }
};
exports.default = _default;