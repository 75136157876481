"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  projectId: null,
  projectName: null,
  repoId: null,
  customersList: []
};
var mutations = {
  updateProject: function updateProject(state, payload) {
    state.projectId = payload.projectId;
    state.projectName = payload.projectName;
    state.repoId = payload.repoId;
  },
  clearProject: function clearProject(state) {
    state.projectId = null;
    state.projectName = null;
    state.repoId = null;
  },
  saveCustomers: function saveCustomers(state, payload) {
    state.customersList = payload.customersList;
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations
};
exports.default = _default;