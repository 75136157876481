"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/objectSpread2"));

var _zh_CN = _interopRequireDefault(require("ant-design-vue/es/locale/zh_CN"));

var _xqjrPluginCommon = require("xqjr-plugin-common");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
var _default = {
  name: "App",
  components: {
    Common: _xqjrPluginCommon.Common
  },
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  data: function data() {
    return {
      isRouterAlive: true,
      zh_CN: _zh_CN.default
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)(["app"])),
  created: function created() {
    var _this = this;

    // 加载动态系统配置
    if (process.env.VUE_APP_DYNAMIC_CONFIG === "true") {
      this.$store.dispatch("settings/changeSetting");
    } // 用于标记请求状态，防止相同状态重复弹出提醒


    localStorage.removeItem("reqStatus"); // const userMsg = localStorage.getItem("userMsg")
    // !!userMsg && this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(userMsg)))
    // // 在页面刷新时将vuex里的信息保存到localStorage里
    // window.addEventListener("beforeunload", () => {
    //   localStorage.setItem("userMsg", JSON.stringify(this.$store.state));
    // });

    this.$store.dispatch("auth/checkToken").catch(function () {
      _this.$store.dispatch("auth/refreshToken").catch(function () {
        _this.$store.dispatch("auth/resetToken");
      });
    });
  },
  methods: {
    reload: function reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  }
};
exports.default = _default;