"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customersList = customersList;
exports.companiesList = companiesList;

var _request = _interopRequireDefault(require("./request"));

// 查找所有
function customersList(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customers/listAll",
    method: "get",
    params: params
  });
} // 查找所有


function companiesList(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/companies/listAll",
    method: "get",
    params: params
  });
}