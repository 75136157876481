"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.join");

require("core-js/modules/es.array.slice");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertPic = exports.dataLeftCompleting = exports.getPageTitle = void 0;

var _settings = _interopRequireDefault(require("@/settings"));

var title = _settings.default.system_title || "";

var getPageTitle = function getPageTitle(pageTitle) {
  if (pageTitle) {
    return "".concat(pageTitle, "\u2014\u2014").concat(title);
  }

  return "".concat(title);
};

exports.getPageTitle = getPageTitle;

var dataLeftCompleting = function dataLeftCompleting(bits, identifier, value) {
  value = Array(bits + 1).join(identifier) + value;
  return value.slice(-bits);
};

exports.dataLeftCompleting = dataLeftCompleting;

var convertPic = function convertPic() {
  var data = arguments[0];
  console.log(data);

  for (var key in arguments[0]) {
    for (var i = 1; i < arguments.length; i++) {
      if (key === arguments[i] && arguments[0][key]) {
        var reg = /^http(s)?:\/\/(.*?)\//;
        var url = location.href.split("/");
        data[key] = arguments[0][key].replace(reg, url[0] + "//" + url[2] + "/");
      }
    }
  }

  console.log(data);
  return data;
};

exports.convertPic = convertPic;